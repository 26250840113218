body[data-template='members'] {
  .list__headers,
  .list__cells {
    & > *:nth-child(1) { @include cols(1, 4); }
    & > *:nth-child(2) { @include cols(5, 4); }
    & > *:nth-child(3) { @include cols(9, 2); }
    & > *:nth-child(4) {
      @include cols(11, 2);
      @include screen('mobile') {
        display: none;
      }
    }
  }

  .toolbar {
    padding: var(--spacing-1);
  }
}
