.article-metas {
  color: var(--color-accent-safe);

  &__metas {
    display: flex;
    flex-wrap: wrap;
  }

  &__meta {
    @include font('meta');
  }

  &__meta:not(:last-child)::after {
    content: ',\00a0';
  }
}
