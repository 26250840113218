.card--creation {
  position: relative;

  display: block;
  margin: var(--spacing-1);
  overflow: hidden;

  figure {
    position: relative;
    aspect-ratio: var(--ratio);

    &::after {
      @include easing(opacity);
      content: '';
      position: absolute;
      inset: 0;
      opacity: 1;
      backdrop-filter: blur(var(--blur));
      -webkit-backdrop-filter: blur(var(--blur));
    }
  }

  &:hover figure::after {
    opacity: 0;
  }

  &__header {
    position: absolute;
    color: var(--color-white);
    padding: var(--spacing-1);
    inset: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.01));
  }

  &__title {
    font-weight: 500;
    font-size: 12rem;
    line-height: 90%;
    margin-bottom: var(--spacing-0);

    @include screen ('mobile') {
      @include font('h1');
    }
  }

  &__subtitle {
    @include font('large');

    @include screen ('mobile') {
      @include font;
    }
  }
}
