.card--article--row {
  @include grid;
  --figure-cols: 2;

  padding: var(--spacing-1);

  @include screen('small') {
    --figure-cols: 4;
  }

  @include screen('mobile') {
    display: flex;
    flex-direction: column;
  }

  &.is-large {
    --figure-cols: 6;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-accent-safe);
  }

  .article-cover {
    @include cols(1, var(--figure-cols));

    @include screen('mobile') {
      &.is-empty {
        display: none;
      }
    }
  }

  &__content {
    @include cols(calc(var(--figure-cols) + 1), calc(12 - var(--figure-cols)));

    display: flex;
    flex-direction: column;
    gap: var(--spacing-0);
  }

  &__header {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__title {
    @include font('h3');
    max-width: 70%;
    margin-bottom: var(--spacing-1);

    @include screen('mobile') {
      max-width: unset;
    }
  }

  .article-metas {
    margin-top: auto;
  }

  &__text {
    display: flex;
    align-items: flex-end;
    gap: var(--spacing-1);

    @include screen('mobile') {
      flex-direction: column;
    }
  }

  &__text .prose {
    flex: 1;
    padding-right: var(--spacing-0);
  }
}
