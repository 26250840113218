body[data-template='shows'] {
  main > section:first-child {
    --columns: 5;

    display: grid;
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    gap: var(--spacing-1);
    margin: var(--spacing-1);
    margin-bottom: 0;

    @include screen('small') { --columns: 4; }
    @include screen('mobile') { --columns: 2; }
  }
}
