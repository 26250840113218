#toggleSearch { display: none; }
#toggleSearch:not(:checked) + .search {
  @include no-select;
  pointer-events: none;
  opacity: 0;
}

.search {
  @include easing(var(--fast), opacity);
  @include font(footer);
  @include inset(0, fixed);

  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  color: var(--color-white);
  display: grid;
  place-items: center;
  backdrop-filter: blur(var(--blur));
  -webkit-backdrop-filter: blur(var(--blur));

  form {
    position: relative;
    width: 60vw;
    min-width: 30rem;
    max-width: 90vw;
  }

  label[for=toggleSearch] {
    @include hitbox(1rem, false);

    position: absolute;
    top: var(--spacing-1);
    right: var(--spacing-1);
    cursor: pointer;

    &:hover {
      color: var(--accent);
    }
  }

  input, button {
    @include reset-input;
  }

  input[type=search] {
    color: var(--color-white);
    border-radius: 3.2rem;
    border: 1px solid;
    text-transform: uppercase;
    min-width: 100%;
    width: 100%;
    padding: var(--spacing-1);
    padding-right: 3rem;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance:none;
    }

    &::placeholder {
      @include easing(opacity);
      color: var(--color-white);
      opacity: 0.8;
    }
  }

  button {
    @include hitbox(1rem);
    cursor: pointer;
    position: absolute;
    right: var(--spacing-1);
    top: 50%;
    transform: translateY(-50%);
    &:hover svg { fill: var(--color-accent); }
  }
}
