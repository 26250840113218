.footer {
  position: relative;
  background: var(--color-accent);
  padding: var(--spacing-1);

  ::selection  {
    background: var(--color-black);
    color: var(--color-accent);
    text-shadow: none;
  }

  &--sticky {
    @include font('menu');
    z-index: 2;
    position: sticky;
    bottom: -1px;

    .flexgroup {
      @include screen('mobile') {
        display: none;
      }
    }
  }

  &--secondary {
    @include font('footer');
    @include grid;
    padding-bottom: var(--spacing-5);

    @include screen('mobile') {
      display: flex;
      flex-direction: column;
    }
  }

  &__links {
    @include no-select;

    display: flex;
    justify-content: space-between;
  }

  &--sticky &__links {
    gap: var(--spacing-1);
  }

  &--secondary &__links {
    flex-direction: column;
  }

  hr {
    visibility: hidden;
    height: var(--spacing-1);

    @include screen('mobile') {
      display: none;
      & + * {
        margin-top: var(--spacing-1);
      }
    }
  }

  section {
    display: flex;
    flex-direction: column;
  }

  section:nth-child(1) { @include cols(1, 4); }
  section:nth-child(2) { @include cols(5, 8); }

  &__link {
    text-transform: lowercase;
  }

  &--sticky &__link--name {
    text-transform: unset;
    a {
      display: flex;
      gap: var(--spacing-0);
      align-items: center;
    }
  }

  &__credits {
    @include font('menu');
    position: absolute;
    left: var(--spacing-1);
    bottom: var(--spacing-1);
    gap: 0.6ch;

    display: flex;
    align-items: center;

    svg {
      height: 1em;
    }
  }
}
