.list {
  padding-bottom: 1px;

  &__headers, &__cells {
    @include grid;
    white-space: nowrap;
    // Columns are defined in the appropriate template

    @include screen('mobile') {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: var(--spacing-0);
    }

    [data-align='right'] {
      text-align: right;
    }
  }

  &__index {
    display: flex;
    padding: var(--spacing-0) var(--spacing-1) var(--spacing-1);
    justify-content: space-between;

    @include screen('mobile') {
      display: none;
    }
  }

  &__headers {
    @include no-select;
    @include font('meta');
    padding: var(--spacing-0) var(--spacing-1);

    @include screen('mobile') {
      display: none;
    }
  }

  &__header {
    &[data-sortable] {
      @include easing(text-decoration-color);

      span {
        display: inline-block;
        text-decoration: underline;
        text-decoration-color: transparent;
        cursor: pointer;
      }

      span:hover {
        text-decoration-color: currentcolor;
      }
    }

    &[data-sorted]::before,
    &[data-sorted]::after {
      display: inline-block;
    }

    &[data-sorted='asc'][data-align='right']::before { content: '↑\00a0' }
    &[data-sorted='desc'][data-align='right']::before { content: '↓\00a0' }
    &[data-sorted='asc'][data-align='left']::after { content: '\00a0↑' }
    &[data-sorted='desc'][data-align='left']::after { content: '\00a0↓' }
  }

  &__content {}

  &__item {
    @include easing((background, border-bottom-color));

    display: block;
    padding: var(--spacing-1);
    text-decoration: none;

    &[id] {
      scroll-margin-top: var(--menu-height);
    }

    & + & {
      margin-top: -1px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-gray-50);
    }

    &:target {
      background: var(--color-gray-10);
      border-top: 1px solid var(--color-gray-50);
      margin-top: -2px;
    }

    &:hover {
      background: var(--color-accent);
      border-color: transparent;
    }
  }

  &__item:has(&__accordion):not(.is-open) {
    cursor: pointer;
  }

  &__item &__cells {
    @include font('large');
  }

  &__item &__cell {
    overflow: hidden;
    text-overflow: ellipsis;

    @include screen('mobile') {
      &:first-child {
        @include font('h3');
        width: 100%;
      }

      &[data-header]:not(:first-child)::before {
        content: attr(data-header);
        display: block;
        font-size: small;
        color: var(--color-gray-90);
      }
    }
  }

  &__item &__accordion {
    padding-top: var(--spacing-1);
  }

  &__item.is-open &__accordion {
    display: grid !important;
  }

  &__item.is-open {
    transition: none;

    color: var(--color-white);
    background: var(--color-black);
  }
}
