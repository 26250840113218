.filter.cta {
  --icon-color: var(--color-gray-50);
  --icon-color-active: var(--color-accent-safe);
  --border-color: var(--color-gray-10);
  --border-color-active: var(--color-accent-safe);
  --background-color: var(--color-gray-10);
  --background-color-active: var(--color-white);
  --placeholder-color: var(--color-gray-50);

  cursor: unset;
  gap: var(--spacing-0);

  border-color: var(--border-color);
  background: var(--background-color);

  svg {
    min-width: 1.6rem;
    color: var(--icon-color);
  }

  &:hover,
  &:has(input:focus),
  &:has(input:not(:placeholder-shown)) {
    background-color: var(--background-color-active);
    border-color: var(--border-color-active);

    svg {
      color: var(--icon-color-active);
    }
  }

  input[type='search'] {
    @include reset-input;
    @include font('meta');

    height: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2px;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance:none;
    }

    &::placeholder {
      color: var(--placeholder-color);
    }
  }
}
