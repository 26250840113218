.article {
  margin-block: var(--spacing-3);

  &__header {
    @include max-width-container;
  }

  &__title {
    @include font('h1');
    margin-bottom: var(--spacing-5);
  }

  &__subtitle {
    @include font('h3');
    color: var(--color-gray-50);
    margin-bottom: var(--spacing-0);
  }

  .article-metas {
    margin-bottom: var(--spacing-1);
  }

  &__cover {
    @include max-width-container;
    margin-bottom: var(--spacing-1);
  }

  &__content {
    @include max-width-container;
  }

  &__content figure {
    margin-inline: var(--spacing-1);
    grid-column: full;

    img {
      margin-inline: auto;
      min-width: Min(70rem, 100%);
      width: auto;
      max-width: 100%;
    }

    &[data-full-width] img {
      margin-inline: unset;
      width: 100%;
    }
  }
}
