@import '~photoswipe/dist/photoswipe';
@import '~photoswipe/dist/default-skin/default-skin';
@import '~flickity/css/flickity.css';
@import '~flickity/css/flickity.css';
@import 'sass/reset';
@import 'sass/fonts';
@import 'sass/helpers';

$max-width: 1920px;
$breakpoints: (
  'large': ($max-width, 9999px),
  'medium': (1080px, $max-width),
  'small': (800px, 1080px),
  'mobile': (0px, 800px),
  'vsmall': (0px, 390px)
);

:root {
  --color-black: #262228;
  --color-white: #FFFFFF;
  --color-green: #40D76A;
  --color-gray-10: #F6F6F6;
  --color-gray-50: #CACACA;
  --color-gray-90: #686868;

  --color-accent: #FFE249;
  --color-accent-safe: var(--color-gray-50);

  --ratio: 2.35;
  --blur: 20px;
  --easing: 200ms;

  --spacing-0: 0.8rem;
  --spacing-1: 2rem;
  --spacing-2: 4rem;
  --spacing-3: 6rem;
  --spacing-4: 8rem;
  --spacing-5: 10rem;

  --cta-height: 3.6rem;
  --menu-height: 7.5rem;

  --font-family: Inter, sans-serif;
  --font-size: 1.8rem;
  --font-weight: 400;
  --font-style: normal;
  --line-height: 135%;
  --text-underline-offset: 0.5rem;
  --text-decoration-thickness: 1px;

   @include font('menu', (
    font-size: 1.4rem,
    font-weight: 500
  ));

  @include font('meta', (
    font-size: 1.3rem
  ));

  @include font('large', (
    font-size: 2.0rem,
    font-weight: 400,
    line-height: 125%,
    text-underline-offset: 0.3rem,
    text-decoration-thickness: 2px,
  ));

  @include font('footer', (
    font-size: 2.2rem,
    font-weight: 500,
    line-height: 125%,
    text-underline-offset: 0.3rem,
    text-decoration-thickness: 2px,
  ));

  @include font('h1', (
    font-family: Everett,
    font-size: 7.2rem,
    font-weight: 500,
    line-height: 95%,
  ));

  @include font('h2', (
    font-family: Everett,
    font-size: 5.0rem,
    font-weight: 500,
    line-height: 105%,
    text-decoration-thickness: 3px,
    text-underline-offset: 0.7rem
  ));

  @include font('h3', (
    font-family: Everett,
    font-size: 3rem,
    font-weight: 500,
    line-height: 105%,
    text-decoration-thickness: 2px,
    text-underline-offset: 0.5rem
  ));

  @include font('h4', (
    font-size: 2.2rem,
    font-weight: 500,
    line-height: 105%,
    text-underline-offset: 0.3rem,
    text-decoration-thickness: 2px,
  ));

  @include screen('mobile') {
    --spacing-0: 0.8rem;
    --spacing-1: 1.4rem;
    --spacing-2: 1.6rem;
    --spacing-3: 3rem;
    --spacing-4: 4rem;
    --spacing-5: 8rem;

    --cta-height: 4.6rem;

    @include font('h1', (
      font-size: 4.9rem,
      line-height: 105%
    ));

    @include font('h2', (
      font-size: 3rem
    ));

    @include font('h3', (
      font-size: 2.4rem
    ));
  }
}

@mixin font-size ($suffix: '') {
  font-size: var(--font-size--#{$suffix}, var(--font-size));
  line-height: var(--line-height--#{$suffix}, var(--line-height));
}

@import 'sass/base';
@import 'sass/prose';

@import 'components/Article';
@import 'components/ArticleMetas';
@import 'components/Breadcrumb';
@import 'components/cards/ArticleRow';
@import 'components/cards/ArticleCover';
@import 'components/cards/ArticleGrid';
@import 'components/cards/Creation';
@import 'components/cards/Member';
@import 'components/cards/Members';
@import 'components/Cta';
@import 'components/Filter';
@import 'components/Footer';
@import 'components/Gallery';
@import 'components/List';
@import 'components/Member';
@import 'components/Menu';
@import 'components/News';
@import 'components/Pagination';
@import 'components/Partners';
@import 'components/Photoswipe';
@import 'components/Picklist';
@import 'components/Search';
@import 'components/ShowAccordion';
@import 'components/ShowMetas';
@import 'components/Slideshow';
@import 'components/Toolbar';

@import 'templates/collection';
@import 'templates/home';
@import 'templates/member';
@import 'templates/members';
@import 'templates/show';
@import 'templates/shows';

body[data-uri^='association'] {
  @include screen('mobile') {
    .toolbar__tools {
      display: none;
    }
  }
}
