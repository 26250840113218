.gallery {
  --columns: 3;

  display: grid;
  padding: var(--spacing-1);
  gap: var(--spacing-1);

  grid-template-columns: repeat(var(--columns), 1fr);

  @include screen('mobile') {
    --columns: 1;
  }

  figure {
    position: relative;
  }

  figure.is-hidden {
    display: none;
  }

  img:not([src]) {
    visibility: hidden;
  }

  figcaption {
    @include easing(opacity);
    @include no-select;
    @include font('meta');
    pointer-events: none;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    color: var(--color-white);
    padding: var(--spacing-1);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
  }

  figure:hover figcaption {
    opacity: 1;
  }
}
