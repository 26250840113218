.partners {
  display: grid;
  padding: var(--spacing-1);
  gap: var(--spacing-1);
  background: var(--color-gray-10);
  grid-template-columns: repeat(4, 1fr);

  @include screen('small') {
    grid-template-columns: repeat(3, 1fr);
  }

  @include screen('mobile') {
    grid-template-columns: repeat(2, 1fr);
  }

  &__partner {
    background: var(--color-white);
    display: grid;
    place-items: center;
    padding: var(--spacing-1);

    img {
      @include easing(filter);
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      filter: grayscale(100%);
    }

    &:hover img {
      filter: none;
    }
  }
}
