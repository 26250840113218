.card--member {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-decoration: none;

  figure {
    @include easing(filter);
    flex: 1;
    filter: grayscale(100%);

    img {
      aspect-ratio: 1;
    }
  }

  &:hover figure {
    filter: none;
  }

  &__name {
    @include font;
    margin-top: var(--spacing-0);
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info {
    @include font('menu');
    border-top: 1px solid var(--color-accent-safe);
    margin-top: calc(var(--spacing-0) / 2);
    padding-top: calc(var(--spacing-0) / 2);
  }
}
