html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after { box-sizing: inherit; }

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; // fix for Chrome
}

body {
  @include font;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  min-height: 100vh;
  background: var(--color-white);
  color: var(--color-black);

  display: flex;
  flex-direction: column;
  max-width: $max-width;
  margin: 0 auto;

  overflow-x: hidden;
}

mark,
::selection  {
  background: var(--color-accent);
  color: var(--color-black);
  text-shadow: none;
}

a {
  @include easing(text-decoration-color);
  color: inherit;
  text-underline-offset: inherit;
  text-decoration-thickness: inherit;

  &:not(:hover) {
    text-decoration-color: transparent;
  }

  .prose & {
    text-decoration-color: currentcolor;
  }
}

a, label {
  -webkit-tap-highlight-color: RGB(255 255 255 / 0%);
}

img, canvas, svg, iframe, object, video {
  @include no-select;

  display: block;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

img {
  width: 100%;
  height: auto;
}

figure img {
  object-position: var(--focus-x, 50%) var(--focus-y, 50%);
  object-fit: cover;
  height: 100%;
  width: 100%;
}

figure.has-photoswipe {
  cursor: pointer;
  cursor: zoom-in;
}

.flexgroup {
  display: flex;
  flex-direction: inherit;
  gap: inherit;
  justify-content: inherit;
  align-items: inherit;
}

main {
  flex: 1;
}

.mobile-only {
  display: none;
  @include screen('mobile') {
    display: initial;
  }
}
