.breadcrumb {
  @include easing(color);

  padding: var(--spacing-1);

  body[data-template='article'] &,
  body[data-template='new'] &,
  body[data-template='show'] & {
    color: var(--color-accent-safe);

    &__link:last-child::after {
      content: '/';
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
  }

  &__link {
    @include font('h2');

    white-space: nowrap;

    @include screen('mobile') {
      white-space: unset;
    }

    &:not(:last-child)::after {
      content: '→\00a0';
    }

    a {
      // Trim underline
      display: inline-block;
    }
  }
}
