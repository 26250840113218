.show-metas {
  h3 {
    @include font('large');
    margin-bottom: var(--spacing-1);
  }

  &__roles {
    margin-top: var(--spacing-3);
  }

  &__role {
    padding-block: var(--spacing-0);
    border-bottom: 1px solid var(--color-gray-90);

    display: grid;
    grid-template-columns: repeat(2, 1fr);


    @include screen('mobile') {
      grid-template-columns: 1fr;
      border-bottom: 0;

      span:first-child {
        font-size: small;
        color: var(--color-gray-50);
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
