.toolbar {
  @include no-select;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-1);

  &__label {
    @include font('h2');
    flex: 1;
  }

  &__tools {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-0);

    @include screen('mobile') {
      .filter {
        display: none;
      }
    }
  }
}
