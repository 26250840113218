.article-cover {
  @include easing(background);

  display: block;
  background: var(--color-accent);

  aspect-ratio: var(--ratio);

  &[data-type='icon'] {
    display: grid;
    place-items: center;
  }

  figure {
    width: 100%;
    height: 100%;
  }

  img {
    @include easing(filter);
    filter: grayscale(100%);
    mix-blend-mode: multiply;
  }

  svg {
    width: auto;
    height: 60%;
    object-fit: contain;
  }
}
