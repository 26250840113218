body[data-template='show'] {
  main {
    color: var(--color-white);
    background: var(--color-black);
  }

  .breadcrumb {
    color: var(--color-gray-90);
  }

  .article .slideshow {
    grid-column: full;
    margin-bottom: var(--spacing-1);
    figure {
      height: calc(100vw / var(--ratio));
    }
  }

  .article__title {
    margin-bottom: var(--spacing-1);

    aside {
      @include font('meta');
      margin-top: var(--spacing-0);
    }
  }

}
