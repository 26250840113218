.cta {
  @include reset-input;
  @include no-select;
  @include easing((color, background-color));
  @include font('meta');

  // Using SASS vars to limite CSS vars scope and allow fallbacks
  $color-text: var(--color-text, var(--color-black));
  $color-background: var(--color-background, var(--color-white));

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-inline: 1.4rem;
  height: var(--cta-height);
  width: fit-content;
  white-space: nowrap;

  border: 1px solid $color-text;
  border-radius: calc(var(--cta-height) / 2);
  color: $color-text;
  background: $color-background;

  &.is-active,
  &:active,
  &:hover {
    border-color: var(--color-accent);
    background: var(--color-accent);
  }

  &[disabled],
  &.is-disabled {
    opacity: 0.3;
    color: $color-text;
    background: $color-background;
  }

  &:is(a) {
    text-decoration: none;
  }
}

summary.cta::-webkit-details-marker {
  display :none;
}
