.prose {
  @include font;

  a {
    word-break: break-all;
  }

  strong, b {
    font-weight: 600;
  }

  & > *:first-child {
    margin-top: 0;
  }
}

.prose > {
  h2,
  h3,
  h4,
  h5,
  h6,
  ul, ul ul,
  details,
  figure,
  p {
    margin-bottom: var(--spacing-1);
  }

  ul li {
    margin-bottom: var(--spacing-0);
  }

  h2 {
    @include font('h2');
    margin-top: 1em;
  }

  h3 { @include font('h3') }
  h4 { @include font('h3'); }

  ul, ul ul {
    list-style: disc outside;

    @include screen('mobile') {
      padding-left: 1em
    }
  }

  figure.video {
    width: 100%;
    aspect-ratio: 16/9;
    margin-inline: 0;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
