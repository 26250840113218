.show-accordion {
  @include grid;

  @include screen('mobile') {
    display: block;
  }

  &__poster {
    @include cols(1, 4);
    display: block;

    @include screen('mobile') {
      margin-bottom: var(--spacing-1);
    }

    figure {
      width: 100%;
    }

    img {
      height: auto;
    }
  }

  .show-metas {
    @include cols(5, 8);
    display: flex;
    flex-direction: column;

    .prose {
      margin-bottom: auto;
    }

    .cta {
      margin-left: auto;
    }
  }
}
