.slideshow {
  --background: #1d1b20;
  --button-offset: var(--spacing-1);

  background: var(--background);

  figure {
    // position is set by Flickity
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    figcaption {
      @include font('meta');
      padding: var(--spacing-1);
      color: var(--color-white);
      font-size: larger;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .flickity-button {
    @include easing(transform);
    @include reset-input;
    background: var(--color-white);

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }

    &:active {
      opacity: 1;
      background: var(--color-accent);
      transform: translateY(-50%) scale(1);
    }

    &:focus {
      box-shadow: none;
    }
  }

  .flickity-prev-next-button {
    width: var(--spacing-1);
    height: var(--spacing-1);
    padding: var(--spacing-1);
    border-radius: 100%;
  }

  .flickity-button-icon {
    top: calc(var(--spacing-1) - 1rem);
    width: 2rem;
    height: 2rem;
    fill: var(--color-black);

    @include screen('mobile') {
    top: calc(var(--spacing-1) - 0.9rem);
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .flickity-prev-next-button.previous {
    left: var(--button-offset);
    svg {
      left: calc(var(--spacing-1) - 1.2rem);

      @include screen('mobile') {
        left: calc(var(--spacing-1) - 1.1rem);
      }
    }
  }

  .flickity-prev-next-button.next {
    right: var(--button-offset);
    svg {
      left: calc(var(--spacing-1) - 1rem);

      @include screen('mobile') {
        left: calc(var(--spacing-1) - 0.9rem);
      }
    }
  }
}
