body:has(#toggleMenu:checked) {
  overflow: hidden;
}

.menu {
  @include no-select;
  @include grid;

  z-index: 3;
  top: 0;
  padding: var(--spacing-1);
  position: sticky;
  align-items: center;
  background: var(--color-white);

  border-bottom: 1px solid var(--color-gray-50);

  &__home {
    @include cols(1, 3);

    @include screens(('vsmall', 'small')) {
      @include cols(1, 2);
      svg:last-child {
        display: none;
      }
    }

    @include screen('mobile') {
      grid-column: unset;
    }

    svg {
      @include no-drag;
    }
  }

  &__home, &__links {
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
  }

  &__links {
    @include cols(5, 8);
    justify-content: space-between;

    @include screen('small') {
      @include cols(4, 9);
    }
  }

  &__link {
    @include easing(color);
    @include font('menu');

    text-transform: lowercase;
    white-space: nowrap;

    @include screen('mobile') {
      display: none;
    }

    &.is-active a {
      text-decoration-color: currentcolor;
    }
  }

  label {
    cursor: pointer;
  }

  label[for='toggleMenu'] {
    position: absolute;
    top: 1.9rem;
    right: var(--spacing-1);
    display: none;

    @include screen('mobile') {
      display: block;
    }
  }

  // Mobile
  #toggleMenu:checked ~ &__links {
    position: fixed;
    top: 5.6rem;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-white);
    padding: var(--spacing-0) var(--spacing-1) var(--spacing-1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .flexgroup {
      display: none;
    }

    .menu__account {
      background: var(--color-accent);
      margin: calc(-1 * var(--spacing-1));
      margin-top: auto;
      padding: var(--spacing-1);
      width: calc(100% + var(--spacing-1) * 2);

      a {
        @include font('large');
        display: block;
        & + a {
          margin-top: 0.4em;
        }
      }
    }

    form {
      position: relative;
      align-self: stretch;
      margin-inline: calc(-1 * var(--spacing-1));
      padding: var(--spacing-1);
      border-top: 1px solid var(--color-gray-50);
      border-bottom: 1px solid var(--color-gray-50);

      input {
        @include reset-input;
        width: 100%;
        &::placeholder {
          color: var(--color-gray-50);
        }
      }
    }

    .menu__link {
      @include font('h2');
      display: block;
    }
  }
}
