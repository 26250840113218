.news {
  --columns: 4;

  display: grid;
  gap: var(--spacing-1);
  grid-template-columns: repeat(var(--columns), 1fr);

  padding: var(--spacing-1);

  @include screen('small') {
    --columns: 2;
  }

  @include screen('mobile') {
    --columns: 1;
    gap: var(--spacing-4);
  }

  .article-cover[data-type='image'] {
    background: none;
  }

  .card--article--grid:hover .article-cover img {
    filter: none;
  }
}
