@keyframes fade-in { from { opacity: 0; } to { opacity: 1; } }
@keyframes fade-out { from { opacity: 1; } to { opacity: 0; display: none; pointer-events: none; } }

body[data-template='home'] {
  .toolbar {
    padding: var(--spacing-1);

    @include screen('mobile') {
      margin-top: var(--spacing-2);

      .cta {
        display: none;
      }
    }
  }

  .welcome {
    position: relative;

    .slideshow {
      height: calc(100vw / var(--ratio));

      figcaption {
        background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));

        @include screen('mobile') {
          @include font;
        }
      }
    }

    &__splash {
      z-index: 1;
      position: absolute;
      inset: 0;
      padding: var(--spacing-5);
      backdrop-filter: blur(var(--blur));
      -webkit-backdrop-filter: blur(var(--blur));

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      path {
        fill: var(--color-white);
      }
    }

    .welcome__splash {
      animation: fade-out 1200ms 800ms cubic-bezier(.7,.08,.53,.97) both;
    }

    .cta, figcaption, .flickity-button {
      animation: fade-in 1200ms 800ms cubic-bezier(.7,.08,.53,.97) both;
    }

    .cta {
      position: absolute;
      top: var(--spacing-1);
      right: var(--spacing-1);
      border: 0;

      @include screen('mobile') {
        display: none;
      }
    }
  }

  .shows .slideshow {
    --background: none;
    --button-offset: var(--spacing-0);
    padding: var(--spacing-1);
    padding-top: 0;

    .flickity-cell {
      --columns: 5;
      display: block;
      width: calc((100% - (var(--spacing-1) * (var(--columns) - 1))) / var(--columns));
      margin-right: var(--spacing-1);

      @include screen('small') {
        --columns: 3;
      }

      @include screen('mobile') {
        --columns: 2;
      }

      figure {
        height: 100%;
      }
    }
  }

  .news {
    padding-top: 0;
  }
}
