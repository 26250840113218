.picklist {
  @include font('meta');
  --offset-x: 0;

  position: relative;

  &[open] summary,
  &:has(:checked) summary {
    border-color: var(--color-accent);
    background: var(--color-accent);
  }

  &__content {
    z-index: 3;
    margin-top: var(--spacing-0);
    position: absolute;
    width: 22rem;
    left: var(--offset-x);
    border-radius: calc(var(--cta-height) / 2);
    background: var(--color-accent);
    overflow: hidden;

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .filter {
    --icon-color-active: var(--color-black);
    --border-color: var(--placeholder-color);
    --border-color-active: var(--border-color);
    --background-color: none;
    --background-color-active: var(--background-color);
    --icon-color: rgba(0, 0, 0, 0.3);
    --placeholder-color: rgba(0, 0, 0, 0.3);

    padding-inline: 1.6rem;
    padding-block: 0.6rem 0.4rem;

    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;

    width: 100%;

    ::selection {
      background: var(--color-black);
    }
  }

  &__values {
    @include invisible-scroll(y);
    padding: 1.1rem;
    max-height: 50rem;
  }

  &__value {
    overflow: hidden;
    text-overflow: ellipsis;

    @include screen('mobile') {
      line-height: 2.1;
    }

    & + & {
      margin-top: 0.6em;
    }

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: var(--spacing-0);

      input[type='checkbox'] {
        @include reset-input;

        position: relative;
        cursor: pointer;
        margin-left: 0.4rem;
        width: 1.2rem;
        height: 1.2rem;
        border: 1px solid var(--color-black);
        border-radius: 3px;

        &:checked::after {
          content: '';
          position: absolute;
          inset: 0.2rem;
          background: var(--color-black);
          border-radius: 100%;
        }
      }
    }
  }
}
