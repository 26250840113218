.card--article--grid {
  overflow: hidden;

  .article-cover {
    aspect-ratio: 1.85;
  }

  .article-metas {
    padding-block: var(--spacing-0);
    color: var(--color-text)
  }

  &__title {
    @include font('h4');
    margin-bottom: var(--spacing-0);
  }

  .prose {
    @include font('meta');
  }
}
