.card--members {
  --columns: 7;

  display: grid;
  gap: var(--spacing-1);
  padding: var(--spacing-1);
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));

  @include screen('small') {
    --columns: 4;
  }

  @include screen('mobile') {
    --columns: 2;
  }

  &__divider {
    @include no-select;
    @include font('h1');
    pointer-events: none;
    overflow: hidden;

    display: grid;
    background: var(--color-accent);
    place-items: center;

    font-size: 18rem;

    @include screen('mobile') {
      display: none;
    }
  }
}
