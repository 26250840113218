.member {
  &__header {
    @include grid;
    padding: var(--spacing-1);

    @include screen('mobile') {
      display: flex;
      flex-direction: column;
    }

    figure {
      @include cols(1, 2);
      img {
        object-position: center top;
        object-fit: contain;
      }
    }

    article {
      display: flex;
      flex-direction: column;
      @include cols(3, 10);
    }

    .toolbar {
      margin-top: auto;
    }
  }

  &__shows .toolbar {
    padding: var(--spacing-1);
  }
}
